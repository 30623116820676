import React, { useState } from 'react';
import { Card, Form, Button, FormCheck } from 'react-bootstrap';  
import { Link, useLocation } from 'react-router-dom';
import { ImArrowLeft } from 'react-icons/im';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import useTaskRulePost from 'hooks/useTaskRulePost';
import { format } from 'date-fns';

const TaskEdit = () => {
  const location = useLocation();
  const { item } = location.state || {};  
  const parameters = item?.parameters || {}; 
  const { postData } = useTaskRulePost();




  const [formValues, setFormValues] = useState(() => {
    const initialValues = {};
    Object.keys(parameters).forEach(key => {
      if (parameters[key].type === 'bool') {
        initialValues[key] = false; 
      } else {
        initialValues[key] = ''; 
      }
    });
    return initialValues;
  });

  const handleInputChange = (key, value, type) => {
    let formattedValue;
    if (type === 'int') {
      formattedValue = parseInt(value, 10) || '';
    } else if (type === 'bool') {
      formattedValue = value; 
    } else if (type === 'date') {
      formattedValue = format(value, 'yyyy-MM-dd'); 
    } else if (type === 'datetime') {
      
      formattedValue = format(value, "yyyy-MM-dd'T'HH:mm:ssXXX"); 
    } else {
      formattedValue = value;
    }

    setFormValues(prev => ({
      ...prev,
      [key]: formattedValue
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postData(item.id, formValues);  
  };

  const renderInputs = () => {
    return Object.keys(parameters).map(key => {
      const param = parameters[key];

      if (param.type === 'bool') {
       
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            <FormCheck 
              type="switch"
              id={key}
              checked={formValues[key]}  
              onChange={(e) => handleInputChange(key, e.target.checked, 'bool')}
            />
        </Form.Group>
        );
      } else if (param.type === 'date') {
        
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            <DatePicker
              selected={formValues[key] ? new Date(formValues[key]) : null}
              onChange={(date) => handleInputChange(key, date, 'date')}
              dateFormat="yyyy-MM-dd"
              className="form-control"
              placeholderText={`${key} tarihini seçiniz..`}
            />
          </Form.Group>
        );
      } else if (param.type === 'datetime') {
       
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            <DatePicker
              selected={formValues[key] ? new Date(formValues[key]) : null}
              onChange={(date) => handleInputChange(key, date, 'datetime')}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd HH:mm"
              className="form-control"
              placeholderText={`${key} tarih ve saati seçiniz..`}
            />
          </Form.Group>
        );
      } else {
       
        const inputType = param.type === 'int' ? 'number' : 'text';
        return (
          <Form.Group key={key} className="mb-3">
            <Form.Label>
              {key} {param.required && <span style={{ color: 'red' }}>*</span>}:
            </Form.Label>
            <Form.Control
              type={inputType}
              value={formValues[key]}  
              required={param.required}
              onChange={(e) => handleInputChange(key, e.target.value, param.type)} 
              placeholder={`${key} giriniz..`} 
            />
          </Form.Group>
        );
      }
    });
  };

  return (
    <>
      <Link to="/integration/task-rules">
        <ImArrowLeft style={{ fontSize: '25px' }} />
      </Link>

     
      <Card className="mb-3 mt-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '40px', margin: '0 auto' }}>
        <div style={{ width: '100%' }}>
        <div className="mb-3 mt-3" >
            <p><strong>Task Name:</strong> {item?.task_name}</p> 
            <p><strong>Task Register Name:</strong> {item?.task_register_name}</p> 
          </div>
          <hr />
          
          <Form  onSubmit={handleSubmit} style={{paddingLeft:'100px', paddingRight:'100px'}}>
            {renderInputs()} 
            
            { Object.keys(parameters).length === 0 ? <p style={{textAlign:'center'}}>Henüz Parametre Oluşturulmamıştır.</p>  :
                <div className="d-flex justify-content-center"> 
                  <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>
                    Onayla
                  </Button>
                </div>
              }
          </Form>
        </div>
      </Card>
    </>
  );
};

export default TaskEdit;
